// jQuery free
import {effectShakeElement} from './effects';
import {onDOMReady, requestIdleCallback} from './dom';

const CLASS_VEHICLE_SEARCH_FORM = 'vehicleSearch__form';
const CLASS_VEHICLE_SEARCH_INPUT = 'vehicleSearch__input';
const CLASS_VEHICLE_SEARCH_CHAR = 'vehicleSearch__char';
const CLASS_VEHICLE_SEARCH_CHAR_COUNTER = 'vehicleSearch__charCounter';
const CLASS_VEHICLE_SEARCH_CARRET = 'vehicleSearch__carret';
const CLASS_ACTIVE = '--active';
const CLASS_OK = '--ok';


function initVehicleSearch() {
    const forms = document.getElementsByClassName(CLASS_VEHICLE_SEARCH_FORM);

    for (const form of forms) {
        /** @type {HTMLFormElement} form */

        const input = form.getElementsByClassName(CLASS_VEHICLE_SEARCH_INPUT)[0];
        const chars = form.getElementsByClassName(CLASS_VEHICLE_SEARCH_CHAR);
        const charCounter = form.parentNode.getElementsByClassName(CLASS_VEHICLE_SEARCH_CHAR_COUNTER)[0];
        const length = parseInt(input.getAttribute('minlength'));

        const carret = document.createElement('span');
        carret.classList.add(CLASS_VEHICLE_SEARCH_CARRET);


        const sanitizeValue = () => {
            const originalValue = input.value.toUpperCase();
            input.value = originalValue.replace(/[^0-9A-Z]/gi, '').substring(0, length);

            return originalValue !== input.value;
        };

        const refreshChars = () => {
            let carretAdded = false;

            for (let i = 0; i < chars.length; i++) {
                const char = input.value.charAt(i);
                chars[i].innerHTML = input.value.charAt(i);
                if ((char === '' && carretAdded === false) || (input.value.length === length && i === (length - 1))) {
                    chars[i].appendChild(carret);
                    carretAdded = true;
                }
            }

            charCounter.innerHTML = `${input.value.length}/${length}`;
            if (input.value.length === length) {
                form.classList.add(CLASS_OK);
            } else {
                form.classList.remove(CLASS_OK);
            }
        };

        const disabledHandler = () => {
            if (input.hasAttribute('disabled')) {
                window.kvik_ajax.sendAjaxRequestFromElement(input);
                return true;
            }

            return false;
        };

        const formSubmitHandler = (event) => {
            if (sanitizeValue() || input.value.length !== length) {
                effectShakeElement(form);
                event.preventDefault();
            }
        };

        const inputChangeHandler = () => {
            if (sanitizeValue()) {
                effectShakeElement(form);
            }
            refreshChars();
        };


        if (input) {
            const isDisabled = input.hasAttribute('disabled');
            inputChangeHandler();
            form.addEventListener('submit', (event) => disabledHandler() || formSubmitHandler(event));
            form.addEventListener('click', () => disabledHandler() || inputChangeHandler());
            input.addEventListener('input', () => disabledHandler() || inputChangeHandler());
            input.addEventListener('change', () => disabledHandler() || inputChangeHandler());
            input.addEventListener('focus', () => disabledHandler() || (() => {
                form.classList.add(CLASS_ACTIVE);
                charCounter.classList.add(CLASS_ACTIVE);
            })());
            input.addEventListener('blur', () => disabledHandler() || (() => {
                form.classList.remove(CLASS_ACTIVE);
                charCounter.classList.remove(CLASS_ACTIVE);
            })());
        }
    }
}

onDOMReady(() => {
    initVehicleSearch();
    document.addEventListener(window.km_modal.EVENT_KM_MODAL_SHOW_MODAL, () => {
        initVehicleSearch();
    });
});


class KvikKbaNumberVehicleSearchElement extends HTMLElement {
    #input0;
    #input1;
    #inputSearch;
    #form;

    // noinspection JSUnusedGlobalSymbols
    connectedCallback() {
        requestIdleCallback(() => {
            const inputs = this.getElementsByClassName('vehicleSearch__kba__input');
            this.#inputSearch = this.querySelector('input[name=search]');
            this.#form = this.#inputSearch.form;
            this.#input0 = inputs[0];
            this.#input1 = inputs[1];

            const sanitizeValue = (value, maxLength) => {
                return value.toUpperCase().replace(/[^0-9A-Z]/gi, '').substring(0, maxLength);
            };

            const sanitizeInputs = () => {
                this.#input0.value = sanitizeValue(this.#input0.value, this.#input0.maxLength);
                this.#input1.value = sanitizeValue(this.#input1.value, this.#input1.maxLength);
                this.#inputSearch.value = this.#input0.value + this.#input1.value;

                return this.#inputSearch.value.length >= this.#inputSearch.minLength;
            };

            sanitizeInputs();

            this.#input0.addEventListener('input', () => {
                sanitizeInputs();
                if (this.#input0.value.length >= this.#input0.maxLength) {
                    this.#input1.focus();
                }
            });

            this.#input0.addEventListener('paste', (event) => {
                const sanitizedPasteFull = sanitizeValue(event.clipboardData.getData('text'), this.#inputSearch.maxLength);
                this.#input0.value = sanitizedPasteFull.substring(0, this.#input0.maxLength);
                if (sanitizedPasteFull.length > this.#input0.maxLength) {
                    this.#input1.value = sanitizedPasteFull.substring(this.#input0.maxLength, this.#input0.maxLength + this.#input1.maxLength);
                }
                sanitizeInputs();
            });

            this.#input1.addEventListener('input', () => sanitizeInputs());

            this.#input1.addEventListener('keydown', (event) => {
                sanitizeInputs();
                if (event.key === 'Backspace' && this.#input1.value.length === 0) {
                    this.#input0.focus();
                }
            });

            this.#form.addEventListener('submit', (event) => {
                if (!sanitizeInputs()) {
                    effectShakeElement(this.#form);
                    event.preventDefault();
                }
            });
        });
    }
}

customElements.define('kvik-kbanumbervehiclesearch', KvikKbaNumberVehicleSearchElement);