// jQuery free
import {onDOMReady} from './dom';

onDOMReady(function () {
    const initVehicleManufacturers = () => {

        const vehicleManufacturers = document.getElementsByClassName('vehicleManufacturers');

        if (vehicleManufacturers.length) {
            const showMoreBtns = vehicleManufacturers[0].getElementsByClassName('vehicleManufacturers__showMoreBtn');
            if (showMoreBtns.length) {
                for (const showMoreBtn of showMoreBtns) {
                    showMoreBtn.addEventListener('click', function () {
                        const showMore = this;
                        const parent = showMore.parentElement;
                        const moreLinks = Array.from(parent.getElementsByClassName('vehicleManufacturers__link--more'));

                        for (const link of moreLinks) {
                            link.classList.remove('vehicleManufacturers__link--more');
                        }

                        showMore.remove();
                    });
                }
            }
        }
    };

    initVehicleManufacturers();

    document.addEventListener(window.km_modal.EVENT_KM_MODAL_SHOW_MODAL, () => {
        initVehicleManufacturers();
    });
});
